import uniqid from "uniqid";
import { useTranslation } from "react-i18next";
import { POTREE_DISPLAY_MODES } from "../../../constants/potree";
import { potreeTools } from "../../../utils/potreeTools";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import AddScene from "../scenes/AddScene";
import Authenticated from "../shared/Authenticated";

const TabsBar = () => {
  const { t } = useTranslation();
  const { potreeViewer } = usePotree();
  const { potreeDisplayMode, setPotreeDisplayMode } = useToggle();

  if (!potreeViewer) return null;

  return (
    <Wrapper
      elementType="section"
      classNames="z-10 absolute bottom-6 left-1/2 transform -translate-x-1/2 rounded-lg p-2 bg-[#F5F5F5]"
    >
      <ul className="flex flex-row items-center gap-3">
        {POTREE_DISPLAY_MODES.map((displayMode) => {
          const label = POTREE_DISPLAY_MODES.find(
            ({ value }) => value === displayMode.value
          )?.value;

          return (
            <li
              key={uniqid()}
              onClick={() => {
                setPotreeDisplayMode(displayMode.value);
                potreeTools[displayMode.action](potreeViewer);
              }}
              className={`capitalize transition cursor-pointer px-4 py-2 rounded-lg ${
                potreeDisplayMode === displayMode.value
                  ? "bg-primary text-white"
                  : "text-[#CDCDCD]"
              }`}
            >
              {t(`${label}`)}
            </li>
          );
        })}
        <Authenticated>
          <AddScene />
        </Authenticated>
      </ul>
    </Wrapper>
  );
};

export default TabsBar;
