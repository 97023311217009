import { usePotree } from "../../../hooks/usePotree";
import LanguageSwitcher from "../../shared/LanguageSwitcher";
import Label from "../../shared/Label";

const Shortcuts = () => {
  const { theme } = usePotree();

  return (
    <div className="flex flex-col justify-between gap-4">
      <button className="text-primary flex items-center gap-8">
        <Label text="shortcuts" theme={theme} />
      </button>

      <LanguageSwitcher theme="light" />
    </div>
  );
};

export default Shortcuts;
