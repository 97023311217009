import uniqid from "uniqid";
import { toast } from "react-toastify";
import { GrMenu } from "react-icons/gr";
import { VscArrowRight } from "react-icons/vsc";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { POTREE_DISPLAY_MODES } from "../../../constants/potree";
import { potreeTools } from "../../../utils/potreeTools";
import { authTokenHeader } from "../../../utils/helper";
import { useAuth } from "../../../hooks/useAuth";
import { usePotree } from "../../../hooks/usePotree";
import PopupMenu from "../../shared/PopupMenu";
import Input from "../shared/Input";

const DEFAULT_NEW_SCENE = {
  label: "",
  selectedSceneTypeKey: "",
  showForm: false,
};

const AddScene = () => {
  const { token } = useAuth();
  const { project, setProject, potreeViewer } = usePotree();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [newScene, setNewScene] = useState(DEFAULT_NEW_SCENE);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setErrors({});

    if (newScene.showForm && inputRef.current) {
      inputRef.current.focus();
    }
  }, [newScene.showForm, newScene.selectedSceneTypeKey]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({});
    setNewScene((prev) => ({
      ...prev,
      label: e.target.value,
    }));
  };

  const addScene = async () => {
    const sceneTypeKey = newScene.selectedSceneTypeKey;
    const volumesData: any = potreeTools.getVolumesData(potreeViewer);
    const viewData: any = potreeTools.getViewData(potreeViewer);
    const pointcloudsData = potreeTools.getPointcloudsData(potreeViewer);

    const body: any = JSON.stringify({
      label: newScene.label,
      field_scene_type: sceneTypeKey,
      json_data: [
        {
          type: "attributes",
          value: {
            volumes: volumesData,
            view: viewData,
            pointclouds: pointcloudsData,
          },
        },
      ],
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${project?.id}/scene`,
        {
          method: "PUT",
          headers: authTokenHeader(token),
          body,
        }
      );

      const newScene = await response.json();

      if (newScene && response.ok) {
        toast.success(`Scene '${newScene.label}' added.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes: [
                ...prevProject.field_scenes[sceneTypeKey].scenes,
                newScene,
              ],
            },
          },
        }));

        return;
      }

      toast.error(`Adding scene '${newScene.label}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const handleSelectSceneType = (sceneTypeKey: string) => {
    setNewScene((prev) => ({
      ...prev,
      showForm: true,
      selectedSceneTypeKey: sceneTypeKey,
    }));
  };

  const handleAddScene = () => {
    if (!newScene.label.trim()) {
      setErrors((prev) => ({
        ...prev,
        label: "Scene label is required.",
      }));

      return;
    }

    addScene();

    setNewScene(DEFAULT_NEW_SCENE);
  };

  return (
    <div className="relative bg-[#EEEEEE] p-2 rounded-lg flex items-center">
      <PopupMenu
        id={uniqid()}
        menuIcon={GrMenu}
        handleEdit={() => handleSelectSceneType("ground_view")}
        editLabel="ground_view"
        handleDelete={() => handleSelectSceneType("cutting")}
        deleteLabel="cutting"
        menuIconStyles="text-primary"
        popupMenuStyles="-top-[110px] left-[48px]"
      />

      {POTREE_DISPLAY_MODES.find(
        ({ value }) => value === newScene.selectedSceneTypeKey
      ) &&
        newScene.showForm && (
          <div className="absolute left-[56px]">
            <Input
              name="label"
              value={newScene.label}
              placeholder={
                POTREE_DISPLAY_MODES.find(
                  ({ value }) => value === newScene.selectedSceneTypeKey
                )?.label
              }
              handleInputChange={handleInputChange}
              error={errors.label}
              classNames="h-12"
              ref={inputRef}
            />
            <button
              className="absolute top-3 right-3 hover:scale-125"
              onClick={handleAddScene}
            >
              <VscArrowRight size={24} className="text-primary" />
            </button>
          </div>
        )}
    </div>
  );
};

export default AddScene;
