import { useTranslation } from "react-i18next";
import Label from "../../shared/Label";

const ProgressBar = ({
  progress,
  label,
}: {
  progress: number;
  label?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      {label && <Label text={t(`${label}`)} />}

      <div className="flex items-center relative w-full h-2 bg-lgray text-white rounded-3xl overflow-hidden">
        <span
          className="absolute h-2 bg-gray"
          style={{ width: `${progress}%` }}
        ></span>
      </div>
    </div>
  );
};

export default ProgressBar;
