import { useTranslation } from "react-i18next";
import { Theme } from "../../types";

interface LabelProps {
  theme?: Theme;
  text: string;
  classNames?: string;
}

const Label: React.FC<LabelProps> = ({ theme, text, classNames }) => {
  const { t } = useTranslation();

  return (
    <label
      className={`capitalize text-base-regular 
      ${classNames ?? ""} 
      ${theme === "dark" ? "text-secondary" : "text-primary"}
      `}
    >
      {t(`${text}`)}
    </label>
  );
};

export default Label;
